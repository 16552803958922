exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-angle-grinder-js": () => import("./../../../src/pages/projects/angle-grinder.js" /* webpackChunkName: "component---src-pages-projects-angle-grinder-js" */),
  "component---src-pages-projects-b-side-js": () => import("./../../../src/pages/projects/b_side.js" /* webpackChunkName: "component---src-pages-projects-b-side-js" */),
  "component---src-pages-projects-climate-group-js": () => import("./../../../src/pages/projects/climate-group.js" /* webpackChunkName: "component---src-pages-projects-climate-group-js" */),
  "component---src-pages-projects-code-project-js": () => import("./../../../src/pages/projects/code-project.js" /* webpackChunkName: "component---src-pages-projects-code-project-js" */),
  "component---src-pages-projects-fishards-js": () => import("./../../../src/pages/projects/fishards.js" /* webpackChunkName: "component---src-pages-projects-fishards-js" */)
}

